import React from "react"
import FotoKontigo from "@components/pageCasosdeExito/image/imgPhotoEmpresa/astara/FotoAstara.png"
import FotoKontigo2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/astara/FotoAstara2.png"

//logo de empresas clientes
import Limautos from "@components/pageCasosdeExito/image/logoscustomer/Astara.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import gestion from "@components/pageCasosdeExito/image/iconcards/gestion.svg"
import campañas from "@components/pageCasosdeExito/image/iconcards/campañas.svg"
import chatbot from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/astara/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/astara/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/astara/img3.svg"

const text1 = (
  <span className="">
    Astara es una de las principales concesionarias de automóviles en Perú que
    lidera la comercialización de las marcas de autos más representativas, como
    Kia, Mitsubishi, Peugeot, MG, Chery y más.
    <br /> <br />
    La distribuidora ha sido capaz de mejorar sus principales métricas de ventas
    gracias a Beex, software omnicanal que les ayuda a potenciar los procesos
    dentro de su embudo de ventas, logrando multiplicar en 3x sus ventas
    digitales.
  </span>
)

const text2 = (
  <span>
    Astara, empresa con más de 15 años de exitosa presencia en el mercado
    automotriz, es el retailer del Grupo Astara Perú, líder automotriz en la
    región, con presencia en todo Latinoamérica, en países como Chile,
    Argentina, Colombia y Perú.
    <br /> <br />
    Actualmente representa y distribuye las marcas de automóviles más
    representativas del territorio, dedicándose a la comercialización de
    vehículos nuevos, seminuevos, accesorios, repuestos originales y servicios
    de postventa.
    <br /> <br />
    Jonny Castillo, Jefe Comercial de Astara, ha sido uno de los principales
    contactos para llevar a cabo la implementación y desarrollo de Beex en la
    operación de Astara, específicamente para las ventas digitales de autos
    seminuevos, donde han conseguido mejorar con creces sus métricas más
    importantes de negocio.
    <br /> <br />
    Para entender mejor la relevancia que ha tenido la implementación de Beex
    en la operación de ventas de Astara, hay que tener en cuenta que es la
    herramienta principal de prospección de leads digitales, ya sea por Ads u
    orgánicos, además de ser vital para sus procesos de remarketing, con las
    campañas masivas de WhatsApp.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Con Beex hemos logrado un nivel de contactabilidad del 80% con nuestros
    leads digitales. Incluso, hemos recuperado mucho la cartera que antes
    teníamos perdida. Si antes mis ventas digitales representaban el 15%, ahora
    son del 40%.”
  </p>
)

const text4 = (
  <span>
    Beex se implementó de forma rápida y eficiente gracias a la coordinación
    entre los equipos del cliente y Beex. Sin duda, lo principal fue
    acceder a la API de WhatsApp Business, la cual se llevó a cabo en días para
    que pueda ser usada en sus chatbots y campañas masivas digitales.
  </span>
)

const text5 = (
  <span>
    WhatsApp se ha convertido en el canal principal de ventas para Astara, y
    es que, en su modelo de negocio, el 90% de su funnel de ventas es por origen
    digital, ya sea por Facebook, webpage u otros.
    <br /> <br />
    Teniendo en cuenta esto, Astara diseño e implementó chatbots en WhatsApp
    que puedan prospectar mejor a sus leads digitales. Con ellos, tienen un
    filtro inicial que ayuda a conocer mejor a sus clientes potenciales para
    pasarlos una etapa de cierre con sus asesores de ventas conectados a Beex.
    <br /> <br />
    Como menciona Jonny, sus usuarios prefieren usar un canal digital como
    WhatsApp para conocer mejor sobre precios, características, promociones y
    más de las diversas marcas que comercializan, es por ello que tener una
    herramienta que gestione de manera eficiente sus canales digitales ha hecho
    potenciar su operación.
  </span>
)
const text6 = (
  <span>
    “El 70% de nuestros clientes prefieren que los contactemos por WhatsApp. Es
    por ello que Beex nos ayuda en el proceso de contacto y comunicación,
    dándonos real visibilidad del canal. Si antes respondíamos al cliente en 72
    horas, ahora lo hacemos en 72 minutos. La eficiencia que hemos logrado se ha
    multiplicado en mucho.”
  </span>
)

const text7 = (
  <span>
    Para los leads digitales que no son atendidos por su operación, por
    cualquier motivo, o para aquellos que se deben contactar a futuro, Astara
    implementó una estrategia de remarketing, donde el canal de contacto es
    WhatsApp.
    <br /> <br />
    Con Beex y la API de WhatsApp Business, Astara es capaz de diseñar y
    programar campañas de WhatsApp masivos según las segmentaciones que
    necesiten, ya sea para enviar ofertas, promociones, información
    complementaria o más.
    <br /> <br />
    De hecho, uno de los puntos más importantes, no solo para el proceso de
    remarketing de Astara, sino en general, es el poder segmentar
    adecuadamente sus leads en Beex. Esto lo han logrado hacer manual y
    automáticamente por diversas propiedades, ya sea por intenciones, origen,
    status o más.
    <br /> <br />
    Contemplando esto, como bien dice Jonny, ya no necesitan enviar campañas
    masivas de miles de mensajes, ya que, con el detalle de la intención de sus
    leads, pueden generar campañas puntuales para lograr los resultados
    deseados.
  </span>
)
const text8 = (
  <span>
    “Con Beex y sus etiquetas podemos tener una segmentación por intención de
    consumo, lo que nos permite plantear mejor nuestro remarketing. Ya no
    necesitamos enviar 3k mensajes para conseguir resultados, ahora nos basta
    con campañas pequeñas de 200 leads para linkear la promoción que deseamos.”
  </span>
)

const text9 = (
  <span>
    Debido a la cantidad de leads de digital que recibe Astara, era primordial
    implementar chatbots que puedan ayudar a sus usuarios, de este modo,
    optimizarían los tiempos de primer contacto y tendrían un canal de
    prospección más óptimo.
    <br /> <br />
    Es por ello que se diseñaron e implementaron chatbots en WhatsApp, que es el
    canal donde llegan sus leads de paga y orgánicos. Estos chatbots usan
    funcionalidades interactivas para mejorar la experiencia del cliente, como
    botones y listas, además de imágenes, videos y documentos.
    <br /> <br />
    A Astara, antes de contar con Beex, les costaba más automatizar su
    operación de Contact Center y los chatbots ayudaron a mejorar sus métricas
    de contacto más importante.
    <br /> <br />
    Como nos cuenta Jonny, el tiempo de respuesta que ahora se les brinda a sus
    usuarios es mucho más rápido que antes, además que los filtros que pasan
    para saber si son leads calificados o no se pueden editar siempre que lo
    necesiten, según su necesidad. Esto, ha hecho que mejoren su proceso de
    ventas.
  </span>
)
const text10 = (
  <span>
    “Los chatbots en WhatsApp nos permiten filtrar en automático y en tiempo
    real a nuestros leads en nuestro embudo de ventas. Antes nos tomábamos hasta
    48 horas para esto, ya que el proceso de Contact Center era menos
    automatizado. Ahora solo es cuestión de minutos.”
  </span>
)

const text11 = (
  <span>
    Algo que también valoran en Astara son las funcionalidades de asignación y
    supervisión que les brinda la plataforma, como la gestión multiagente y el
    monitoreo en tiempo real.
    <br /> <br />
    Antes de contar con el API oficial de WhatsApp Business, les era imposible
    asignar de forma automática y eficiente las conversaciones de sus leads para
    cada uno de sus representantes de ventas. Con Beex, ahora son capaces de
    crear sus colas de atención y que las conversaciones de sus leads puedan
    enrutarse a los representantes que ellos deseen.
    <br /> <br />
    Esto ha hecho que el proceso de prospección se vea optimizado, además que
    ayuda mucho a que cada agente cuenta con una cantidad estimada de leads.
    <br /> <br />
    Además, ahora tienen visibilidad completa de las interacciones de sus
    representantes con sus leads, ya que desde los módulos de supervisión de
    Beex les permite visualizar los espacios de trabajo de cada uno,
    permitiéndoles aplicar acciones de auditoría y mejora.
  </span>
)

const text12 = (
  <span>
    “Antes, no podíamos supervisar algo de lo que no teníamos visibilidad. Con
    Beex ahora somos capaces de monitorear nuestra gestión. Por ejemplo,
    revisar si en realidad estamos respondiendo a tiempo a nuestros leads.
    Además, podemos diversificarlos entre la cantidad de asesores que tenemos,
    de manera que le llegue a quién sea más eficiente en responder.”
  </span>
)

const text13 = (
  <p style={{ marginBottom: "70px" }}>
    Algo que es infaltable en cada operación que soportamos con nuestras
    soluciones, es el acompañamiento que brindamos desde nuestra área de
    Customer Success. Como menciona Jonny, su operación fue clara desde el
    inicio con lo que necesitaban y el compromiso que esperaban, y esto se ha
    cumplido con creces.
    <br /> <br />
    Astara se considera un cliente muy exigente en cuánto al soporte se
    refiere, y el área de Beex Service Desk ha cumplido siempre con sus
    necesidades, tanto para consultas o requerimientos.
    <br /> <br />
    Estos son algunos procesos con los que Beex ayuda
    a Astara en su operación de ventas:
  </p>
)

const text14 = (
  <p style={{ marginBottom: "70px" }}>
    Nos encontramos felices y complacidos de ayudar a una marca tan
    representativa en el mercado automotriz, como Astara. Sin duda, ha sido
    una gran experiencia el poder sumar en su proceso de ventas con nuestra
    tecnología omnicanal, lo que les ha permitido mejorar sus métricas más
    importantes, y a nosotros sumar feedback de una industria de largo alcance.
  </p>
)

const data = {
  intro: {
    image: Limautos,
    link: "#",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoKontigo,
    text4: text4,
    title5: "WhatsApp Business API para Ads y canales orgánicos",
    text5: text5,
    text6: text6,
    title7: "Campañas masivas de WhatsApp para remarketing",
    text7: text7,
    text8: text8,
    fototwo: FotoKontigo2,
    title9: "Chatbots en WhatsApp para prospección",
    text9: text9,
    title10: "Acompañamiento y respaldo que se siente",
    text10: text10,
    title11: "Monitoreo de calidad con el API de WhatsApp Business",
    text11: text11,
    text12: text12,
    text13: text13,
    text14: text14,

    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para su operación de ventas digitales. Beex ha logrado mejorar el contacto y comunicación con sus clientes, optimizando su proceso de prospección con sus leads.",
    },
    {
      title: "Campañas masivas",
      img: campañas,
      info:
        "Envío de campañas masivas de WhatsApp para su operación de marketing y ventas. Proceso simple y rápido con el uso de plantillas pre-aprobadas de WhatsApp, lo que les ha permitido optimizar su proceso de remarketing.",
    },
    {
      title: "Chatbot",
      img: chatbot,
      info:
        "Implementación de chatbots para su operación de ventas. Diseño y creción por rangos horarios para brindar respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año.",
    },
    {
      title: "Gestión multiagente",
      img: gestion,
      info:
        "Creación y administración de múltiples usuarios para gestionar bajo una sola cuenta de WhatsApp. Gestión de colas de atención asignadas a chatbots con enrutamiento inteligente de casos.",
    },
  ],
}

export default data
